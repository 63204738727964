import React, { FunctionComponent } from 'react';
import { LoadingSpinner } from './LoadingSpinner';

export const LoadingScreen: FunctionComponent = () => {
  return (
    <div className="h-screen flex justify-center fixed top-0 left-0 w-full">
      <LoadingSpinner />
    </div>
  );
};

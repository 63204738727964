import Big from 'big.js';
import React from 'react';
import { useHistory } from 'react-router';
import * as fromPublicValidatorStore from '../public-node/validators.store';
import { Link } from './Link';
import { Stake, TxAction } from '../node/stake.store';

export const Validator: React.FC<{
  validator: fromPublicValidatorStore.Validator;
  rank?: number;
  stake?: Stake[];
}> = ({ validator, rank, stake = [] }) => {
  const bnTotalDelegatedStake = new Big(validator.totalDelegatedStake);
  // const bnOwnerStake = new Big(validator.ownerDelegation);
  const divBy = new Big(10).pow(18);
  const history = useHistory();
  return (
    <div>
      <div key={validator.address} className="md:flex mb-10">
        <div className="text-3xl md:p-5 md:w-1/4 md:text-right text-center sm:w-full">
          {bnTotalDelegatedStake
            .div(divBy)
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}

          {/* <div className="text-xs">
                  {bnOwnerStake
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div> */}
        </div>
        <div className="self-center md:w-3/4 md:text-left text-center">
          <div>
            {rank != null && <>#{rank + 1} </>}
            <button
              type="button"
              className="focus:outline-none"
              style={{ color: '#00c389' }}
              onClick={() => {
                history.push(`/validators/${validator.address}`);
              }}
            >
              {validator.name || 'Untitled node'}
            </button>
            {validator.infoURL && (
              <Link url={validator.infoURL}>
                <span className="text-lg ml-1">🔗</span>
              </Link>
            )}
            <div className="text-sm">
              {/* {validator.ip && <div>💻 {validator.ip.split(':')[0]}</div>} */}
              <div>
                {validator.isExternalStakeAccepted ? '✅' : '❌'} External stake
              </div>
              {/* {validator?.geo && (
              <div>
                🌍
                {validator?.geo?.city} {validator?.geo?.country}{' '}
                <span>
                  ({validator?.geo?.ll[0]}, {validator?.geo?.ll[1]})
                </span>
              </div>
            )} */}
            </div>
          </div>

          <div className="break-words text-gray-500 text-xs">
            {validator.address}
          </div>
        </div>
      </div>
      <div className="w-full">
        {stake.map((delegate) => {
          return (
            <div key={delegate.txId} className="mb-10 md:flex">
              <div className="text-xl md:p-5 md:w-1/4 md:text-right text-center sm:w-full">
                {delegate.type === TxAction.StakeTokens ? '+' : '-'}{' '}
                {delegate.amountFormatted}
              </div>
              <div className="self-center md:w-3/4 md:text-left text-center">
                <div className="break-words text-gray-500 text-xs">
                  {delegate.address}
                </div>
                <div>{delegate.sentAt}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

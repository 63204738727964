import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import Big from 'big.js';

export enum TxAction {
  TokenTransfer = 'TokenTransfer',
  StakeTokens = 'StakeTokens',
  UnStakeTokens = 'UnstakeTokens',
}
export interface Stake {
  address: string;
  amount: string;
  sentAt: string;
  type: TxAction;
}

export interface Stake {
  txId: string;
  amountFormatted: string;
  timestamp: number;
}

interface State {
  state: 'initial' | 'pending' | 'success' | 'error';
  data: Record<string, Stake[]>;
}

const initialState: State = { state: 'initial', data: {} };
const divBy = new Big(10).pow(18);

export const { reducer: stakeReducer, actions: stakeActions } = createSlice({
  name: 'stake',
  initialState,
  reducers: {
    getValidatorsStake: (state, _: PayloadAction<string>): State => ({
      ...state,
      state: 'pending',
      data: {},
    }),
    getValidatorsStakeSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{ address: string; delegators: Record<string, Stake> }>
    ): State => {
      const delegators = Object.entries(payload.delegators)
        .map(([txId, value]) => {
          const bnAmount = new Big(value.amount);
          return {
            ...value,
            txId,
            timestamp: DateTime.fromISO(value.sentAt).toMillis(),
            amountFormatted: bnAmount
              .div(divBy)
              .toFixed(0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
          };
        })
        .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
        .reverse();
      return {
        ...state,
        state: 'success',
        data: { ...state.data, [payload.address]: delegators },
      };
    },
    getValidatorsStakeFail: (state): State => ({
      ...state,
      state: 'error',
    }),
  },
});

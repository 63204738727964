import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import * as nodeEpics from '../node/node.epics';
import * as publicNodeEpics from '../public-node/public-node.epics';
import { bootstrapApplicationEpic } from '../settings/settings.epics';

export const rootEpic = combineEpics(
  bootstrapApplicationEpic,
  nodeEpics.getValidatorsEpic,
  publicNodeEpics.getValidatorsEpic,
  // @ts-ignore
  publicNodeEpics.getValidatorEpic,
  nodeEpics.getValidatorStakingEpic
);

export interface IEpicDependencies {
  getJSON: <T>(
    url: string,
    headers?: Record<string, string | undefined> | undefined
  ) => Observable<T>;
  post: typeof ajax.post;
  fetch: typeof window.fetch;
}

export const dependencies: IEpicDependencies = {
  // @ts-ignore
  getJSON: ajax.getJSON,
  post: ajax.post,
  fetch: window.fetch,
};

import React, { FunctionComponent } from 'react';

export const LoadingSpinner: FunctionComponent = () => {
  return (
    <div className="lds-roller self-center">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

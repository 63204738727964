import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { nodeReducer } from '../node/node.store';
import { stakeReducer } from '../node/stake.store';
import { validatorsReducer } from '../public-node/validators.store';
import { queueReducer } from '../queue/queue.store';
import { settingsReducer } from '../settings/settings.store';

const rootReducer = combineReducers({
  settings: settingsReducer,
  queue: queueReducer,
  node: nodeReducer,
  validators: validatorsReducer,
  stake: stakeReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

const settingsState = (state: RootState) => state.settings;
export const getHasInitiated = createDraftSafeSelector(
  settingsState,
  (state) => state.hasInitiated
);

const queueState = (state: RootState) => state.queue;
export const getQueueItems = createDraftSafeSelector(
  queueState,
  (state) => state.items
);
export const getCurrentInQueue = createDraftSafeSelector(
  queueState,
  (state) => state.current
);

const nodeState = (state: RootState) => state.node;
export const getValidators = createDraftSafeSelector(
  nodeState,
  (state) => state.data
);
export const getValidatorsList = createDraftSafeSelector(nodeState, (state) =>
  Object.values(state.data)
);

export const getValidatorsState = createDraftSafeSelector(
  nodeState,
  (state) => state.state
);

const stakeState = (state: RootState) => state.stake;
export const getStake = createDraftSafeSelector(
  stakeState,
  (state) => state.data
);

export const getStakeState = createDraftSafeSelector(
  nodeState,
  (state) => state.state
);

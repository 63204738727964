import { from, Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { Action } from '@reduxjs/toolkit';
import { StateObservable } from 'redux-observable';
import { nodeActions } from './node.store';
import { RootState } from '../store/rootReducer';
import { IEpicDependencies } from '../store/rootEpic';
import { stakeActions } from './stake.store';

// (async () => {
//   await fetch('https://mainnet.radixdlt.com/archive', {
//     method: 'POST',
//     body: JSON.stringify({
//       jsonrpc: '2.0',
//       id: '0',
//       method: 'validators.get_next_epoch_set',
//       params: { size: 200 },
//     }),
//   });
// })();

export const getValidatorsEpic = (
  action$: Observable<Action>,
  state$: StateObservable<RootState>,
  { fetch }: IEpicDependencies
) =>
  action$.pipe(
    filter(nodeActions.getValidators.match),
    switchMap(() =>
      from(
        fetch('https://mainnet.radixdlt.com/archive', {
          method: 'POST',
          body: JSON.stringify({
            jsonrpc: '2.0',
            id: '0',
            method: 'validators.get_next_epoch_set',
            params: { size: 200 },
          }),
        }).then((response) => {
          return response.json();
        })
      ).pipe(
        map((results) => {
          const { validators } = results.result;
          return nodeActions.getValidatorsSuccess(validators);
        }),
        catchError(() => [nodeActions.getValidatorsFail()])
      )
    )
  );

export const getValidatorStakingEpic = (
  action$: Observable<Action>,
  state$: StateObservable<RootState>,
  { fetch }: IEpicDependencies
) =>
  action$.pipe(
    filter(stakeActions.getValidatorsStake.match),
    switchMap((action) =>
      from(
        fetch(
          `https://api.radixno.de/dev/validator-staking/${action.payload}`
        ).then((response) => {
          return response.json();
        })
      ).pipe(
        map((results) => {
          return stakeActions.getValidatorsStakeSuccess({
            address: action.payload,
            delegators: results,
          });
        }),
        catchError(() => [stakeActions.getValidatorsStakeFail()])
      )
    )
  );

import React from 'react';
import { Promoter } from '../../components/Promoter';

export const Benefits: React.FunctionComponent = () => {
  return (
    <div className="px-10 py-10">
      <h2 className="text-2xl text-center capitalize">
        Why stake with RadixNode?
      </h2>
      <div className="mt-10 md:flex">
        {/* <Promoter title="Profit sharing">
          We’re all in this together. By giving back a portion of the profits to
          the stakers yields will be greater. More details on this later when we
          have the node up and running.
        </Promoter> */}
        {/* <Promoter title="Profit sharing">
          We’re all in this together. By giving back a portion of the profits to
          the stakers yields will be greater. More details on this later when we
          have the node up and running.
        </Promoter> */}
        <Promoter title="2% fee">
          In order to provide high quality service we need to cover our
          operating costs and invest the profits into better tools and hardware.
        </Promoter>
        <Promoter title="High availability">
          Our systems are constantly monitoring the health of the validator
          node. In the event of the validator node being unresponsive our
          failover mechanism will trigger and promote the full node into a
          validator.
        </Promoter>
        {/* <Promoter title="Transparency">
          By disclosing all our earnings and costs for anyone to see. Inspired
          by the indie hackers movement, sharing is giving back and promoting
          honesty in the community.
        </Promoter> */}
      </div>
    </div>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import Network from '../../icons/network.svg';

export const Hero: React.FunctionComponent = () => {
  return (
    <div className="h-screen bg-gradient-to-t from-radix-blue to-radix-blue-alt flex justify-center relative overflow-hidden">
      <div className="self-center text-white text-center">
        <div className="relative z-10">
          <h1 style={{ fontSize: 40 }}>Secure the Radix network and profit</h1>
          <h2 className="text-3xl mb-3">Delegate your stake to RadixNode</h2>
          {/* <Link to="/validators/rv1qglytd467jmcgy9ddtns5f79u8dz6k4melq5g2nyaxnzrz36dskeq3nwygc"> */}
          <span className="break-all">
            rv1qglytd467jmcgy9ddtns5f79u8dz6k4melq5g2nyaxnzrz36dskeq3nwygc
          </span>
          {/* </Link> */}

          {/* <Button
            type="green"
            className="mt-5 text-lg opacity-50 cursor-not-allowed"
            onClick={() => {}}
          >
            Stake now
          </Button> */}
          {/* <div className="mt-8 text-lg">
            Node status: <span className="font-bold">Offline</span>{' '}
            <span className="block text-sm">
              Last checked {new Date().toTimeString()}
            </span>
          </div> */}
        </div>

        <div
          className="absolute justify-center top-0 opacity-30"
          style={{ width: 2000 }}
        >
          <img src={Network} alt="network" className="self-center" />
        </div>
      </div>
    </div>
  );
};

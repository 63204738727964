import { from, Observable } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { Action } from '@reduxjs/toolkit';
import { validatorActions } from './validators.store';

export const getValidatorsEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(validatorActions.getValidators.match),
    switchMap(() =>
      from(
        fetch('https://api.radixno.de/dev/public-node', {
          method: 'POST',
          body: JSON.stringify({
            jsonrpc: '2.0',
            method: 'radix.validators',
            params: [200, 'cursor'],
            id: 1,
          }),
        }).then((results) => results.json())
      ).pipe(
        switchMap((results) => {
          return [
            validatorActions.getValidatorsSuccess(results.result.validators),
          ];
        }),
        catchError((err) => {
          return [validatorActions.getValidatorsFail()];
        })
      )
    )
  );

export const getValidatorEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(validatorActions.getValidator.match),
    switchMap((action) =>
      from(
        fetch('https://api.radixno.de/dev/public-node', {
          method: 'POST',
          body: JSON.stringify({
            jsonrpc: '2.0',
            method: 'radix.lookupValidator',
            params: [action.payload],
            id: 1,
          }),
        }).then((results) => results.json())
      ).pipe(
        switchMap((results) => {
          return [validatorActions.getValidatorSuccess(results.result)];
        }),
        catchError(() => {
          return [validatorActions.getValidatorFail()];
        })
      )
    )
  );

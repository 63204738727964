import React from 'react';

export const Link: React.FC<{ url: string }> = ({ url, children }) =>
  url ? (
    <a
      className="font-bold"
      style={{ color: '#00c389' }}
      target="_blank"
      rel="noreferrer"
      href={url}
    >
      {children}
    </a>
  ) : (
    <div>{children}</div>
  );

import React from 'react';

export const About: React.FunctionComponent = () => {
  return (
    <div className="px-10 py-10 bg-gradient-to-t from-radix-blue to-radix-blue-alt flex flex-col">
      <h2 className="text-2xl text-center capitalize text-white">About</h2>
      <div className="mt-10 text-white w-full sm:w-8/12 self-center text-center">
        <p className="mb-3">
          Software engineer by trade with 10+ years of experience developing and
          maintaining applications for the web.
        </p>
        <p className="mb-3">
          Radix has been in the top of my crypto list since 2018. I have deep
          respect for Dan and the Radix team for not selling out or putting a
          half-baked product out into the wild during the ICO mania. They have
          my trust and belief, I want to be part of the network. Besides being
          an early investor (2020 token sale) and participating as a validator
          in the betanet, I plan to learn and develop crypto components for the
          community to use.
        </p>
        {/* <p className="mb-3">
          
        </p> */}
        {/* <p className="mb-3">
          Being a radix node validator feels like a great honor and I hope you
          will put your trust in me by staking your Radix on my node.
        </p> */}
        <p className="mb-3">
          If you have any questions you can reach me on discord or twitter
          xStelea.
        </p>
        {/* <p className="mb-3">Let&apos;s shape the future together!</p> */}
      </div>
    </div>
  );
};

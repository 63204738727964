import React from 'react';

export const Button: React.FunctionComponent<{
  children: React.ReactChild;
  type: 'green' | 'white';
  className?: string;
  onClick: () => void;
}> = ({ children, type, className = '', onClick }) => {
  const greenStyle = `bg-radix-green rounded p-2 px-4 text-white cursor-pointer hover:bg-radix-green ${className}`;
  const whiteStyle = `rounded p-2 px-4 text-white cursor-pointer ${className}`;
  const style = type === 'green' ? greenStyle : whiteStyle;
  return (
    <button type="button" className={style} onClick={() => onClick()}>
      {children}
    </button>
  );
};

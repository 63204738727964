import React from 'react';

export const Server: React.FunctionComponent = () => {
  return (
    <div className="px-10 py-10">
      {/* <h2 className="text-2xl text-center capitalize">RadixNode</h2> */}
      <div className=" md:flex">
        <div className="w-full flex-grow my-4 md:mx-4 text-center">
          <h3 className="text-lg font-bold mb-2">💻 Main node</h3>
          <ul>
            <li>Finland</li>
            <li>AMD Ryzen™ 5 3600</li>
            <li>2 x SSD NVMe 512 GB</li>
            <li>64 GB DDR4</li>
            <li>1 Gbit/s Connection</li>
          </ul>
        </div>
        <div className="w-full flex-grow my-4 md:mx-4 self-center text-center">
          <h3 className="text-lg font-bold mb-2">💻 Backup node</h3>
          <ul>
            <li>Germany</li>
            <li>Intel Core i7-3770</li>
            <li>1x SSD SATA 512 GB</li>
            <li>16 GB DDR3</li>
            <li>1 Gbit/s Connection</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

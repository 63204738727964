import React from 'react';

export const Promoter: React.FunctionComponent<{
  title: string;
  children: React.ReactChild;
}> = ({ title, children }) => {
  return (
    <div className="w-full flex-grow text-justify my-4 md:mx-4">
      <h3 className="text-lg font-bold mb-2">{title}</h3>
      {children}
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum TxAction {
  TokenTransfer = 'TokenTransfer',
  StakeTokens = 'StakeTokens',
  UnStakeTokens = 'UnstakeTokens',
}
export interface Stake {
  address: string;
  amount: string;
  sentAt: string;
  type: TxAction;
}
export interface Validator {
  address: string;
  infoURL: string;
  isExternalStakeAccepted: boolean;
  name: string;
  ownerAddress: string;
  ownerDelegation: string;
  proposalsCompleted: number;
  proposalsMissed: number;
  registered: boolean;
  totalDelegatedStake: string;
  uptimePercentage: string;
  validatorFee: string;
  stake: Record<string, Stake>;
}

interface State {
  state: 'initial' | 'pending' | 'success' | 'error';
  data: Record<string, Validator>;
}

const initialState: State = { state: 'initial', data: {} };

export const { reducer: nodeReducer, actions: nodeActions } = createSlice({
  name: 'node',
  initialState,
  reducers: {
    getValidators: (state): State => ({
      ...state,
      state: 'pending',
      data: {},
    }),
    getValidatorsSuccess: (
      state,
      { payload }: PayloadAction<Validator[]>
    ): State => ({
      ...state,
      state: 'success',
      data: payload.reduce(
        (acc, curr) => ({ ...acc, [curr.address]: curr }),
        {}
      ),
    }),
    getValidatorsFail: (state): State => ({
      ...state,
      state: 'error',
    }),
  },
});

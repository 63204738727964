import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
import store from './store/store';
import App from './App';
import { LandingPage } from './landing-page/LandingPage';
import { ValidatorsPage } from './validators/ValidatorsPage';
import { ValidatorPage } from './validators/ValidatorPage';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App>
        <main>
          <Switch>
            <Route exact path="/">
              <LandingPage />
            </Route>
            <Route exact path="/validators">
              <ValidatorsPage />
            </Route>
            <Route exact path="/validators/:address">
              <ValidatorPage />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </main>
      </App>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

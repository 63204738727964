import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Validator {
  totalDelegatedStake: string;
  address: string;
  infoURL: string;
  ownerDelegation: string;
  name: string;
  ownerAddress: string;
  isExternalStakeAccepted: boolean;
}

interface State {
  state: 'initial' | 'pending' | 'success' | 'error';
  data: Record<string, Validator>;
}

const initialState: State = { data: {}, state: 'initial' };

export const {
  reducer: validatorsReducer,
  actions: validatorActions,
} = createSlice({
  name: 'validators',
  initialState,
  reducers: {
    getValidators: (state) => ({
      ...state,
      validators: {},
      state: 'pending',
    }),
    getValidatorsSuccess: (
      state,
      { payload }: PayloadAction<Validator[]>
    ): State => ({
      ...state,
      state: 'success',
      data: payload.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.address]: curr,
        }),
        {}
      ),
    }),
    getValidatorsFail: (state) => ({
      ...state,
      state: 'error',
    }),
    getValidator: (state, _: PayloadAction<string>) => ({
      ...state,
      state: 'pending',
    }),
    getValidatorSuccess: (state, { payload }: PayloadAction<Validator>) => {
      const validator = payload?.address;
      return validator
        ? {
            ...state,
            state: 'success',
            data: { ...state.data, [payload.address]: payload },
          }
        : { ...state, state: 'success' };
    },
    getValidatorFail: (state) => ({
      ...state,
      state: 'error',
    }),
  },
});

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Header } from '../components/Header';
import { WorldMap } from '../components/WorldMap';
import { nodeActions } from '../node/node.store';
import { getValidatorsList } from '../store/rootReducer';
import { About } from './components/About';
import { Benefits } from './components/Benefits';
import { Hero } from './components/Hero';
import { Server } from './components/Server';

export const LandingPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(nodeActions.getValidators());
  }, []);
  const validators = useSelector(getValidatorsList);
  return (
    <div>
      <Header />
      <Hero />
      <Benefits />
      <div
        className="w-10/12 m-auto bg-radix-green opacity-30"
        style={{ height: 1 }}
      />
      <Server />
      <About />

      {/* <h2 className="text-2xl text-center capitalize">Validators</h2> */}
      {/* <div className="w-screen bg-gradient-to-t bg-white flex justify-center relative overflow-hidden h-56">
        <div className="h-screen w-screen absolute z-10" />
        <div className="z-50 absolute bottom-0 w-full text-xl text-center bg-radix-blue-alt p-2 bg-opacity-75 text-white">
          <Link to="/validators">Explore the validators</Link>
        </div>
        <WorldMap validators={validators} />
      </div> */}
      {/* </div> */}

      {/* <MailingList /> */}
      {/* <Footer /> */}
    </div>
  );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Big from 'big.js';
import { useHistory } from 'react-router-dom';
import { Header } from '../components/Header';
import { validatorActions } from '../public-node/validators.store';
import {
  getStake,
  getValidatorsList,
  getValidatorsState,
} from '../store/rootReducer';
import { nodeActions } from '../node/node.store';
import { LoadingScreen } from '../loading/LoadingScreen';
import { Link } from './Link';
import { Validator } from './Validator';

export const ValidatorsPage: React.FC = () => {
  const dispatch = useDispatch();
  const validators = useSelector(getValidatorsList);
  const state = useSelector(getValidatorsState);

  useEffect(() => {
    dispatch(validatorActions.getValidators());
    dispatch(nodeActions.getValidators());
  }, []);

  if (['pending', 'initial'].includes(state)) {
    return (
      <div>
        <Header />
        <LoadingScreen />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="mt-20 flex flex-col p-5">
        <h1 className="text-5xl md:text-left text-center">Validators</h1>
        <h2 className="text-xl mb-10 md:text-left text-center text-gray-500">
          {validators.length} nodes sorted by delegated stake
        </h2>

        {validators.map((validator, index) => {
          return (
            <Validator
              key={validator.address}
              validator={validator}
              rank={index}
            />
          );
        })}
      </div>
    </div>
  );
};

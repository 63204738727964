import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Header } from '../components/Header';
import { LoadingScreen } from '../loading/LoadingScreen';
import { nodeActions } from '../node/node.store';
import { stakeActions } from '../node/stake.store';
import { validatorActions } from '../public-node/validators.store';
import {
  getStake,
  getValidatorsList,
  getValidatorsState,
} from '../store/rootReducer';
import { Validator } from './Validator';

export const ValidatorPage: React.FC = () => {
  const dispatch = useDispatch();
  const { address } = useParams<{ address: string }>();
  const validatorsList = useSelector(getValidatorsList);
  const state = useSelector(getValidatorsState);
  const validator = validatorsList.find((v) => v.address === address);
  const stake = useSelector(getStake);

  useEffect(() => {
    dispatch(validatorActions.getValidator(address));
    dispatch(nodeActions.getValidators());
    dispatch(stakeActions.getValidatorsStake(address));
  }, []);

  if (['pending', 'initial'].includes(state)) {
    return (
      <div>
        <Header />
        <LoadingScreen />
      </div>
    );
  }
  if (!validator) {
    return (
      <div>
        <Header />
        <div className="mt-20 flex flex-col p-5">
          <Link to="/validators">
            <div className="text-sm text-gray-500 mb-5">
              ↵ Back to validators
            </div>
          </Link>
          <h1 className="text-5xl mb-5 md:text-left text-center">
            Validator not found
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="mt-20 flex flex-col p-5">
        <Link to="/validators">
          <div className="text-sm text-gray-500 mb-5">↵ Back to validators</div>
        </Link>
        <h1 className="text-5xl mb-5 md:text-left text-center">Validator</h1>

        <Validator validator={validator} stake={stake[validator.address]} />
      </div>
    </div>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../icons/logo.svg';
import { Button } from './Button';

export const Header: React.FunctionComponent = () => {
  return (
    <div className="px-10 py-4 fixed top-0 flex justify-between w-full z-10 bg-opacity-80 bg-radix-blue">
      <div className="w-6/12 flex">
        <Link to="/" className="self-center">
          <img
            src={Logo}
            alt="logo"
            className="max-w-xs self-center"
            style={{ width: 100 }}
          />
        </Link>
      </div>
      <div className="flex w-6/12 justify-end">
        <a
          className="mr-5 text-white self-center sm:block"
          href="https://www.radixdlt.com/"
          target="_blank"
          rel="noreferrer"
        >
          Radix <span className="md:inline hidden">DLT</span>
        </a>
        {/* <Button
          type="green"
          onClick={() => {
            window.location.href = 'https://status.radixno.de/';
          }}
        >
          <span>Validators</span>
        </Button> */}
        <Link to="/validators">
          <Button type="green" onClick={() => {}}>
            <span>Validators</span>
          </Button>
        </Link>
      </div>
    </div>
  );
};
